import Button from '@placekit/uikit/components/Button';
import Link from '@placekit/uikit/components/Link';
import Logo from '@placekit/uikit/components/Logo';

const Error404 = () => (
  <section className="max-w-7xl w-full mx-auto py-16 px-4 sm:px-6 lg:px-8">
    <div className="shrink-0 flex justify-center">
      <Link href="/" className="inline-flex text-accent-500">
        <span className="sr-only">{process.env.NEXT_PUBLIC_APP_NAME}</span>
        <Logo size="manual" className="h-12" />
      </Link>
    </div>
    <div className="py-16">
      <div className="text-center">
        <p className="text-base font-semibold text-accent-600">404</p>
        <h1 className="mt-2 text-4xl font-bold text-gray-900 tracking-tight sm:text-5xl sm:tracking-tight">
          Page not found.
        </h1>
        <p className="mt-2 text-base text-gray-500">
          Sorry, we couldn’t find the page you’re looking for.
        </p>
        <div className="mt-6">
          <Button
            href="/"
            label="Go back home"
            kind="none"
            icon="arrow-long-right"
            iconPosition="right"
            className="text-accent-600 hover:text-accent-500"
          />
        </div>
      </div>
    </div>
  </section>
);

export const getStaticProps = () => ({
  props: {
    meta: {
      title: 'Page not found.',
      description: 'Error 404: Page not found',
    },
  },
});

export default Error404;
